export default {
  ja: {
    Home: {
      introduce:{
        p1: '文月ルビーです',
        p2: '私には人と大きく違う愛着や嗜好などはなく、ただ心に浮かんだものを描いています',
        p3: 'しいて惹かれるものをあげるなら、数、連鎖、規則性、遠い世界',
      },
      as_artist:{
        title: '画家として',
        apple_title: 'りんごから宇宙を感じた作品群',
        apple_sub_title: 'りんごの形に魅せられて',
        apple_button: 'りんごの絵の詳細',
        sabian_title: 'サビアンシンボルから影響を受けた作品群',
        sabian_sub_title: '「サビアンシンボル」として知られる黄道上の360の詩的な叡智を絵画で解き明かします',
        sabian_button: 'サビアンシンボルの絵の詳細',
      },
      as_astrologer:{
        title: '占星術研究家として',
        sabian_calculator_description: '西洋占星術研究者に向けて、誕生日の惑星のシンボルを見られるようにSabian Calculator（サビアン計算機）を開発',
        sabian_calculator_button: 'サビアン計算機はこちら',
      },
      as_programmer:{
        title: 'プログラマーとして',
        pluto_js_description: {
          p1: '命術系の占いサイト開発用JavaScriptプログラム「',
          p2: '」を公開',
        },
        pluto_js_button: 'Pluto.jsはこちら',
      },
      about_me:{
        my_story: {
          p1: '文月ルビーと申します。',
          p2: '占星術研究の一環で絵を描きながらプログラマーをしています。1985年に札幌で生まれ、お絵かきと占いが好きな子供でしたが、17歳の時に描きたいものがないことに気づきました。',
          p3: '描く対象を求めて旅行したり多様な人々と仕事や交流をし、人生観や芸術観を養うにつれて心象が自然に浮かんでくるようになりました。',
          p4: '絵画を再開したのは2013年のとき、りんごを観察していると宇宙の律動を感じ、それを描いてみたくなったことが契機になりました。しばらくはりんごの宇宙のシリーズを描いていましたが、2018年の秋分の頃に蝶が水平線を昇るイメージを見たことから、サビアンシンボル(12星座を360個に分割したシンボル)の研究と絵画を始めました。',
        },
        about_astrology: {
          title: '西洋占星術について',
          p1: '西洋占星術（せんせいじゅつ）は、太陽系内の太陽・月・惑星・小惑星などの天体の位置や動きなどと人間・社会のあり方を結びつける占いです。性格や適性を占う場合は、その人の生まれた時の星の位置をホロスコープという図表を作成して読み込むことで占います。',
        },
        about_sabian: {
          title: 'サビアンシンボルについて',
          p1: '西洋占星術は黄道をピザのように30度ずつ12の星座に分割しますが、1度ずつ細分化して360のイメージを割り当てたものがサビアンシンボルです。例えば天秤座1度のサビアンシンボルは「突き通す針により完璧にされた蝶」で分かりやすく自分を表現することを意味していますが、社交的でバランス感覚に優れた天秤座の一側面を表しています。',
        },
      },
    },
    Apple:{
      title: 'りんごから宇宙を感じた作品群',
      poem:{
        p1: 'りんごの姿に豊かさを感じた',
        p2: '天体が地軸を回るような形',
        p3: '芯を中心に浮かぶ五芒星',
        p4: '皮の模様は小さな銀河',
        p5: 'この相似性をりんごで描いた',
      },
    },
    Sabian:{
      title: 'サビアンシンボルから影響を受けた作品群',
    },
    Link:{
      title: 'リンク一覧'
    },
  }
}