import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'

Vue.config.productionTip = false

//スライド
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

//多言語
import VueI18n from 'vue-i18n'
import { data } from '@/lang/index.js'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: window.lang, // デフォルト言語設定
  fallbackLocale: 'ja', // 選択中の言語に対応する文字列が存在しない場合はこの言語の文字列を使用する
  messages: data
})

//アンカースクロール
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

new Vue({
  router,
//  store,
  i18n,
  render: h => h(App)
}).$mount('#app')