<template>
  <div id="app">
    <router-link :to="{name:'Home'}">
      <h1 id="site_title">Ruby Fumizki</h1>
    </router-link>

    <div id="humberger" @click="toggleNav">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <nav :class="{show: is_nav_shown}">
      <ul id="nav_container">
        <li><router-link :to="{name:'Home'}">Home</router-link></li>
        <li><router-link :to="{name:'Home', hash:'#bio'}">About Me</router-link></li>
        <li><router-link :to="{name:'Apple'}">{{$t('Home.as_artist.apple_title')}}</router-link></li>
        <li><router-link :to="{name:'Sabian'}">{{$t('Home.as_artist.sabian_title')}}</router-link></li>
        <li><router-link :to="{name:'Link'}">{{$t('Link.title')}}</router-link></li>

        <li class="sns">
          <a href="https://www.instagram.com/rubyfmzk/" target="_blank">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#a2aed6" stroke="none">
            <path d="M2380 12789 c-344 -33 -683 -134 -985 -293 -235 -124 -426 -266 -625
            -466 -446 -445 -706 -1006 -760 -1636 -8 -92 -10 -1256 -8 -4079 4 -4358 -2
            -3980 64 -4273 219 -980 995 -1757 1977 -1976 294 -66 -79 -61 4357 -61 3817
            0 4021 1 4130 18 502 79 912 254 1283 550 465 371 792 892 921 1470 66 294 61
            -77 61 4347 0 4312 3 4064 -52 4325 -106 508 -343 946 -713 1315 -413 413
            -877 645 -1494 748 -93 15 -405 17 -4086 18 -2192 1 -4023 -2 -4070 -7z m7882
            -989 c174 -11 308 -37 449 -86 541 -188 935 -637 1062 -1208 l22 -101 0 -4005
            0 -4005 -22 -101 c-127 -571 -522 -1020 -1062 -1208 -57 -20 -149 -46 -205
            -59 l-101 -22 -4005 0 -4005 0 -101 22 c-644 143 -1124 623 -1267 1267 l-22
            101 0 4005 0 4005 22 101 c113 506 438 921 893 1138 201 95 377 141 595 155
            178 12 7570 13 7747 1z"></path>
            <path d="M9930 10739 c-367 -56 -637 -384 -617 -749 7 -121 26 -193 79 -300
            58 -117 187 -247 305 -308 215 -111 460 -111 676 -1 108 56 245 191 299 294
            136 262 111 559 -67 793 -149 198 -426 309 -675 271z"></path>
            <path d="M6110 10084 c-905 -83 -1695 -449 -2320 -1074 -412 -412 -712 -897
            -894 -1445 -129 -389 -186 -746 -186 -1165 0 -324 33 -597 106 -890 383 -1533
            1687 -2647 3264 -2790 148 -13 492 -13 640 0 1618 147 2941 1314 3289 2900
            111 508 109 1060 -5 1580 -233 1060 -928 1967 -1896 2474 -379 199 -789 327
            -1248 392 -111 15 -639 28 -750 18z m631 -1009 c496 -65 958 -261 1349 -571
            109 -87 327 -305 414 -414 315 -397 514 -874 577 -1385 16 -129 16 -481 0
            -610 -40 -322 -122 -598 -266 -890 -317 -642 -878 -1135 -1551 -1360 -791
            -264 -1656 -154 -2344 300 -220 145 -466 368 -624 565 -306 383 -498 834 -572
            1345 -27 181 -24 533 5 725 44 291 117 530 245 795 139 288 291 501 521 730
            219 219 418 364 685 499 329 167 649 255 1050 290 88 8 397 -4 511 -19z"></path>
            </g>
            </svg>
          </a>
          <a href="https://twitter.com/rubyfmzk" target="_blank" class="ja">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="26px" height="26px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve">
              <path fill="#a2aed6" d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3
              c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
              c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1
              c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7
              c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
            </svg>
          </a>
        </li>

        <li><a :href="path.en">English</a> | <a :href="path.ja">日本語</a></li>
      </ul>
    </nav>
    <router-view/>
    <footer>

      <p class="sns">
        <a href="https://www.instagram.com/rubyfmzk/" target="_blank">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#a2aed6" stroke="none">
          <path d="M2380 12789 c-344 -33 -683 -134 -985 -293 -235 -124 -426 -266 -625
          -466 -446 -445 -706 -1006 -760 -1636 -8 -92 -10 -1256 -8 -4079 4 -4358 -2
          -3980 64 -4273 219 -980 995 -1757 1977 -1976 294 -66 -79 -61 4357 -61 3817
          0 4021 1 4130 18 502 79 912 254 1283 550 465 371 792 892 921 1470 66 294 61
          -77 61 4347 0 4312 3 4064 -52 4325 -106 508 -343 946 -713 1315 -413 413
          -877 645 -1494 748 -93 15 -405 17 -4086 18 -2192 1 -4023 -2 -4070 -7z m7882
          -989 c174 -11 308 -37 449 -86 541 -188 935 -637 1062 -1208 l22 -101 0 -4005
          0 -4005 -22 -101 c-127 -571 -522 -1020 -1062 -1208 -57 -20 -149 -46 -205
          -59 l-101 -22 -4005 0 -4005 0 -101 22 c-644 143 -1124 623 -1267 1267 l-22
          101 0 4005 0 4005 22 101 c113 506 438 921 893 1138 201 95 377 141 595 155
          178 12 7570 13 7747 1z"></path>
          <path d="M9930 10739 c-367 -56 -637 -384 -617 -749 7 -121 26 -193 79 -300
          58 -117 187 -247 305 -308 215 -111 460 -111 676 -1 108 56 245 191 299 294
          136 262 111 559 -67 793 -149 198 -426 309 -675 271z"></path>
          <path d="M6110 10084 c-905 -83 -1695 -449 -2320 -1074 -412 -412 -712 -897
          -894 -1445 -129 -389 -186 -746 -186 -1165 0 -324 33 -597 106 -890 383 -1533
          1687 -2647 3264 -2790 148 -13 492 -13 640 0 1618 147 2941 1314 3289 2900
          111 508 109 1060 -5 1580 -233 1060 -928 1967 -1896 2474 -379 199 -789 327
          -1248 392 -111 15 -639 28 -750 18z m631 -1009 c496 -65 958 -261 1349 -571
          109 -87 327 -305 414 -414 315 -397 514 -874 577 -1385 16 -129 16 -481 0
          -610 -40 -322 -122 -598 -266 -890 -317 -642 -878 -1135 -1551 -1360 -791
          -264 -1656 -154 -2344 300 -220 145 -466 368 -624 565 -306 383 -498 834 -572
          1345 -27 181 -24 533 5 725 44 291 117 530 245 795 139 288 291 501 521 730
          219 219 418 364 685 499 329 167 649 255 1050 290 88 8 397 -4 511 -19z"></path>
          </g>
          </svg>
        </a>
        <a href="https://twitter.com/rubyfmzk" target="_blank" class="ja">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="26px" height="26px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve">
            <path fill="#a2aed6" d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3
            c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
            c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1
            c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7
            c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
          </svg>
        </a>
      </p>

      <p :path="path">
        <a :href="path.en">English</a> | <a :href="path.ja">日本語</a>
      </p>

      <p class="copyright">©2019 Ruby Fumizki All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      is_nav_shown: false,
      path: {
        en: '/',
        ja: '/ja',
      },
    }
  },
  watch: {
    '$route': function(to){
      //ハンバーガーナビ
      this.is_nav_shown = false

      //言語設定
      this.changeLang(to.path)

      //スクロール位置
      if(!to.hash){
        window.scrollTo(0, 0)//トップへ
      }

      else{
        this.toAnchor(to.hash)
      }

    }
  },
  mounted() {
    this.changeLang(location.pathname)

  },
  updated(){

  },
  methods: {
    toggleNav: function(){
      this.is_nav_shown = !this.is_nav_shown 
    },
    changeLang: function(path){
      //言語変更
      let path_base = path.replace(/^\/ja/, '')
      this.path.en = path_base ? path_base : '/'
      this.path.ja = '/ja' + path_base
    },
    toAnchor: function(hash){
      this.$scrollTo(hash)
    },
  }
}
</script>

<style lang="scss">
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap&family=Noto+Serif+JP:wght@300&display=swap');
* {
  margin: 0;
  padding: 0;
}
 
html {
  background: #000;
  font-size: 62.5%;
}
 
body {
  /*font: 500 1.4rem/1.6 "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;*/
  font: 500 1.4rem/1.6 serif;
  color: #4c4c4c;
  letter-spacing: 0.05em;
}
 
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  font-family: 'EB Garamond', 'Noto Serif JP', serif;
}
 
p {
  /*margin-bottom: 1em;*/
}
 
ul {
  list-style: none;
}
 
table {
  border-collapse: collapse;
  border-spacing: 0px;
}
 
form label {
  cursor: pointer;
}
 
a {
  text-decoration: none;
  color: #a2aed6;
}
a.router-link-exact-active, a.router-link-exact-active:hover{
  color: #c5c5ac;
}

a:hover, a:active, a:focus {
    color: #a2aed6;
}
 
a img {
  border: none;
}

button{
    border: solid 2px #485973;
    border-radius: 4px;
    background: rgb(156,170,170,0.3);
    padding: 6px 30px;
    color: #a2aed6;
    font-size: 13px;
    cursor: pointer;
}

button:focus { outline:0; }

section{
  position: relative;
  border-bottom: solid 1px #848859;
  padding: 0 20px;
}

footer{
  padding: 20px;
  color: #c5c5ac;
  z-index: 9997;
  position: relative;
  text-align: center;
  background: #161609;

  p{
    line-height: 2.2em;
  }

  .sns{
    a{
      margin: 0 5px;
    }
  }
}

@media (min-width: 768px) {
  .pc-none{
    display: none;
  }
}
@media (max-width: 767px) {
  .sp-none{
    display: none;
  }
}

#app{
  color: #c5c5ac;
  font-size: 15px;
  text-align: center;
}

h1#site_title{
  color: #c5c5ac;
  position: fixed;
  z-index: 9998;
  font-size: 5.5vw;
  letter-spacing: 0.1em;
  padding: 1vw 2vw;
}
@media (min-width: 768px) {
  h1#site_title{
    font-size: 32px;
    padding: 10px 20px 0;
  }
}

nav{
  display: none;
  color: #c5c5ac;
  position: fixed;
  z-index: 9998;
  background: #000;
  height: 100vh;
  width: 100vw;
  line-height: 2.6em;

  .sns{
    margin: 15px 0 0;
    a{
      margin: 0 5px 0;
    }
  }
}
nav.show{
  display: block;
}

#nav_container{
  margin: 60px 20px 0;
  text-align: center;

  li{
    line-height: 1.3em;
    margin: 0 0 25px;
    display: block;
  }
}


#humberger {
  cursor: pointer;
  position: fixed;
  right: 2vw;
  top: 25px;
  height: 24px;
  width: 24px;
  display: inline-block;
  box-sizing: border-box;
  z-index: 9999;
}
@media (max-width: 767px) {
  #humberger{
    top: 15px;
  }
}

#humberger div {
  position: absolute;
  left: 0;
  height: 2px;
  width: 24px;
  background: #c5c5ac;;
  display: inline-block;
  box-sizing: border-box;
}
#humberger div:nth-of-type(1) {
  top: 0;
}
#humberger div:nth-of-type(2) {
  top: 5px;
}
#humberger div:nth-of-type(3) {
  top: 10px;
}
#humberger:hover div {
  background: #9aa;
}


</style>
