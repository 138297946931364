import Vue from 'vue'
import VueRouter from 'vue-router'

if(location.pathname.match(/^\/ja/)){
  window.lang = 'ja'
  window.lang_prefix = '/ja'
}
else{
  window.lang = 'en'
  window.lang_prefix = ''
}

Vue.use(VueRouter)

const routes = [
  {
    path: window.lang_prefix + '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: window.lang_prefix + '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  {
    path: window.lang_prefix + '/apple',
    name: 'Apple',
    component: () => import('../views/Apple.vue')
  },
  {
    path: window.lang_prefix + '/sabian',
    name: 'Sabian',
    component: () => import('../views/Sabian.vue')
  },
  {
    path: window.lang_prefix + '/show_image/:series_name/:artwork_id',
    name: 'ShowImage',
    component: () => import('../views/ShowImage.vue')
  },
  {
    path: window.lang_prefix + '/link',
    name: 'Link',
    component: () => import('../views/Link.vue')
  },
  {
    path: '*', 
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
