export default {
  en: {
    Home: {
      introduce:{
        p1: 'I’m Ruby Fumizki',
        p2: 'I don\'t have any attachments or preferences that are very different from others, I just draw what I see in my mind',
        p3: 'Suffice it to say that I am attracted to numbers, chains, regularity, and distant worlds',
      },
      as_artist:{
        title: 'As Artist',
        apple_title: 'The works felt universe through the apple',
        apple_sub_title: 'Fascinated by the shape of the apple',
        apple_button: 'Works',
        sabian_title: 'The works inspired by Sabian Symbols',
        sabian_sub_title: 'Drawing to bring out the immanent values of the 360 degrees of poetic knowledge hidden within the area of astrology known as Sabian Symbols',
        sabian_button: 'Works',
      },
      as_astrologer:{
        title: 'As Astrologer',
        sabian_calculator_description: 'Sabian Calculator is the website for astrologers to see the symbols of the planets on any birthdays',
        sabian_calculator_button: 'Sabian Calculator',
      },
      as_programmer:{
        title: 'As Programmer',
        pluto_js_description: {
          p1: 'Published the JavaScript program "',
          p2: '" for developing a fortune-telling website',
        },
        pluto_js_button: 'Pluto.js',
      },
      about_me:{
        my_story: {
          p1: 'I’m Ruby Fumizki.',
          p2: 'Professionally, I’m a programmer and yet I also have a love of drawing, which at the moment I use as part of my Astrology research. Born in Sapporo in 1985, my childhood was all about my painting and a study of divination; yet when I was 17, I came to realise that I had no real idea what I wanted to draw.',
          p3: 'So I started to travel, working and interacting with different people and experiencing their many diverse perspectives – on life and art – in order to find something inspiring that I wanted to draw, mental pictures began to emerge naturally.',
          p4: 'I took up my painting again in 2013 when I was looking at an apple! I felt that I would try to draw a representation of the movement of the universe. I kept at this for a while, until recently when my special study of the Sabian Symbols began – with a vision of an ascending butterfly at the Autumn Equinox 2018. (Sabian Symbols are 360 detailed images within Astrology)',
        },
        about_astrology: {
          title: 'About Astrology',
          p1: 'Astrology is a method both for psychological insights and divination that links the position and movement of the Sun, Moon and planets with human behaviour and events within society. When we read a person\'s character using Astrology, we create a chart from their time and place of birth; this is called a ‘horoscope’.',
        },
        about_sabian: {
          title: 'About Sabian Symbols',
          p1: 'Whereas mainstream Astrology divides the zodiac into 12 parts of 30 degrees (each like a slice of pizza), we can look into greater detail with 360 Sabian Symbols. Each degree is assigned an image, its ‘Sabian Symbol’, which is a description of a picture having an interpretation of its hidden, metaphorical meaning. For example, the Sabian Symbol of Libra 1° is ‘A butterfly made perfect by a dart through it’, which can mean to express yourself particularly clearly; it represents a sociable, balanced aspect of Libra.',
        },
      },
    },
    Apple:{
      title: 'The works felt universe through the apple',
      poem:{
        p1: 'りんごの姿に豊かさを感じた',
        p2: '天体が地軸を回るような形',
        p3: '芯を中心に浮かぶ五芒星',
        p4: '皮の模様は小さな銀河',
        p5: 'この相似性をりんごで描いた',
      },
    },
    Sabian:{
      title: 'The works inspired by Sabian Symbols',
    },
    Link:{
      title: 'Links List'
    },
  }
}